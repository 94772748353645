export const hero = {
  title:
    "Industry-leading point-of-sale solutions for arenas, stadiums & venues",
  subTitle: "Sports and Entertainment",
  subtext:
    "Upgrade the fan experience by giving them the power to order how they want, when they want.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/sports-and-entertainment/demo",
  },
};

export const image = {
  imageName: "sports-hero.png",
  mobileImageName: "sports-hero-mobile.png",
  title: "SPORTS AND ENTERTAINMENT",
};

export const highlightsSection = {
  title: "Win fans over with a new ordering experience",
  imageName: "sports-highlights.png",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/sports-and-entertainment/demo",
  },
  highlights: [
    {
      title: "Accelerate \n transactions",
      subtext:
        "Maximize untapped revenue with easy-to-learn solutions for POS, self-service kiosks, handheld, and mobile solutions, with intuitive workflows, programmable upsells, and VIP and loyalty tools to improve the fan experience.",
    },
    {
      title: "Accept orders \n anywhere",
      subtext:
        "Reduce congestion and speed up turnaround time by offering in-seat, mobile, and online solutions so fans can order in their seats, suites, or anywhere throughout your venue.",
    },
    {
      title: "Reward fan \n engagement",
      subtext:
        "Enable mobile wallet payments, loaded tickets, loyalty, and member benefit discounts. SpotOn’s robust partner integration ecosystem provides more insight into your fans.",
    },
  ],
};

export const highlightsHero = {
  image: {
    imageName: "sports-highlights-2.png",
    mobileImageName: "sports-highlights-2-mobile.png",
    title: "World-class technology and service",
    subtext: "ENTERPRISE SOLUTIONS",
  },
  title: "Go beyond concessions and simplify your operations",
  highlights: [
    {
      title: "Accurate inventory management",
      subtext:
        "Keep track of every ingredient and inventory item at the SKU level. Don't be left empty-handed with automatic alerts on dropping inventory and supply levels.",
    },
    {
      title: "Powerful business intelligence",
      subtext:
        "Gain real-time visibility into every aspect of your venue’s operations via a central, cloud-based POS platform that can be accessed from any web-enabled device.",
    },
    {
      title: "Cash management & hawking support",
      subtext:
        "Boost efficiency with dedicated solutions to manage cash room and hawking operations thorughout your venue.",
    },
  ],
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/sports-and-entertainment/demo",
  },
};

export const partners = {
  title: "Partnered with the leaders in your industry",
  desktopImageName: "appetize-sports-partners-desktop.png",
  mobileImageName: "appetize-sports-partners-mobile.png",
};

export const heroQuote = {
  title: "Trusted by 65% of major league sports teams",
  quote: `"In our continued efforts to stay ahead of the curve and create a world-class experience at Lincoln Financial Field, we believe SpotOn’s advanced technology will take us to the next level. SpotOn is revolutionizing the customer experience in the sports and entertainment industry. We are excited about their future, our partnership, and integrating their innovative technology into our venue."`,
  personName: "Frank Gumienny",
  personPosition: "Senior VP & CFO Philadelphia Eagles",
  imageName: "sports-hero-quote.png",
  mobileImageName: "sports-hero-quote-mobile.png",
};

export const productsWithLinks = {
  title:
    "A true omnichannel platform designed for the future of the fan experience",
  subtext:
    "Increase sales today and position your organization for a stronger future with a modern, omnichannel solution designed for enterprise venues. Streamline operations, generate more revenue, and convert every bit of data into crucial business intelligence.",
  products: [
    {
      title: "Kiosks",
      description:
        "Give your guests direct control over their ordering experience as you speed up order fulfillment and improve your bottom line.",
      linkTo: "/enterprise/kiosks",
      imageName: "products-kiosk-2.png",
    },
    {
      title: "Handhelds",
      description:
        "Take orders and process payments courtside, tableside, or while guests are still in line using on-the-go point of sale solutions.",
      linkTo: "/enterprise/handhelds",
      imageName: "products-handheld.png",
    },
    {
      title: "Kitchen Displays",
      description:
        "Instantly transmit all POS orders to a centralized device for preparation, even if your internet connection goes down.",
      linkTo: "/enterprise/kitchen-displays",
      imageName: "products-kitchen-display.png",
    },
  ],
};

export const footer = {
  title: "Book a consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/sports-and-entertainment/demo",
  },
};
